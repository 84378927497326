import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import LogoCloud from "../components/Repeating/LogoCloud";
import Testimonials from "../components/Repeating/Testimonials";
import CallToAction from "../components/Repeating/CTA";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
    li {
      ${tw`text-sm lg:text-base tracking-wider lg:tracking-normal`}
    }
  }
  h3 {
    ${tw`font-body normal-case tracking-wider	text-base font-bold`}
  }
  p {
    ${tw`text-sm lg:text-base`}
  }
`;

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Commercial Kitchen Cleaning Services | HCS"
        description="Looking for five-star commercial kitchen cleaning services nationwide? We offer hassle-free cleaning so your culinary staff can do what they do best."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-48 md:pt-64 lg:pt-80 xl:pt-[490px] pb-14 md:pb-52 lg:pb-[415px] relative mb-8 lg:mb-0">
        <BgImage
          className="inset-0 w-full h-full bg-center"
          image={heroImages}
          style={{ position: "absolute" }}
        />
        <div className="container relative">
          <h1 className="mb-4 md:mb-4 md:max-w-[560px]">
            Commercial Kitchen Cleaning
          </h1>
          <p className="md:text-xl mb-0 md:max-w-sm">
            Hassle-free cleaning so your culinary staff can do what they do
            best.
          </p>
        </div>
      </section>

      <LogoCloud className="relative lg:-top-60 mb-16 md:-mb-6" />

      <section className="relative mb-20 md:mb-32">
        <div className="absolute top-0 w-full"></div>
        <div className="relative container">
          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-24">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4">
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="About"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="What We Clean"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="Benefits"
                  stripHash
                  className="no-underline"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <StyledContent>
                <div id="anchor-1" className="mb-14 lg:mb-16">
                  <h2>Let Us Handle the Cleaning</h2>
                  <p>
                    You, your guests, and your culinary team all benefit from
                    having a thoroughly clean and sanitary kitchen environment.
                    At Hotel Cleaning Services, we set your restaurant up for
                    success by ensuring everything in the kitchen is clean from
                    top to bottom. We’ll keep your equipment sparkling and the
                    entire kitchen environment healthy and spot-free.
                  </p>
                </div>

                <div id="anchor-2" className="mb-14 lg:mb-16">
                  <h2 className="text-mobile-4xl md:text-4xl">What We Clean</h2>
                  <p>
                    At Hotel Cleaning Services, we understand that commercial
                    kitchen cleaning is an essential part of your overall guest
                    experience. As your partner, we’ll perform the following:
                  </p>
                  <ul>
                    <li>Equipment & Ovens: Breakdown and Detailed Cleaning</li>
                    <li>Hood Cleaning & Filters</li>
                    <li>Scrubbing Walls</li>
                    <li>Scrubbing Floors & Cleaning Drains</li>
                    <li>Cleaning Ceiling Vents</li>
                  </ul>
                </div>

                <div id="anchor-3">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    The Benefits of Outsourced Commercial Kitchen Cleaning
                  </h2>
                  <h3>Decades of Experience in Hospitality</h3>
                  <p>
                    Over 30 years of experience cleaning luxury hotels and
                    resorts has given our company time to perfect our
                    techniques. We guarantee you will be impressed by the level
                    of cleanliness we provide for your commercial kitchen.
                  </p>

                  <h3>Highly-Trained Staff</h3>
                  <p>
                    We perform a thorough vetting process for all staff members.
                    Our team conducts background checks, reference checks, and
                    comprehensive interviews. You can trust that our staff will
                    be well-suited for cleaning your kitchen areas.
                  </p>

                  <h3>The Proper Tools for High-Quality Results</h3>
                  <p>
                    Having properly trained employees is only half the equation
                    when it comes to high-quality restaurant cleaning. Access to
                    the right equipment is an essential component of what we
                    offer to make your commercial kitchen clean and sanitary.
                  </p>
                </div>
              </StyledContent>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <CallToAction
        heading={[
          "Contact Us ",
          <span className="text-primary-500">Experts</span>,
        ]}
        subtext="Our highly skilled commercial kitchen cleaning team is ready to exceed your expectations. Contact us today!"
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Comercial Kitchen.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Comercial Kitchen.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: {
        eq: "services/2.9 Kitchen Cleaning/1.0 Comercial Kitchen Desktop.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroMobile: file(
      relativePath: {
        eq: "services/2.9 Kitchen Cleaning/1.0 Comercial Kitchen mobile.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
